
import { defineComponent, ref } from 'vue'

const DATA1 = [
  {
    title: '今使っている電話番号をそのままHORIE MOBILEで使えますか？',
    content: `
      ご利用可能です。<br />
      現在ご契約中の携帯電話会社でMNP予約番号を取得後、<br />
      有効期限が10日以上残っている状態で、<br />
      HORIE MOBILEへお申し込み下さい。
    `
  },
  {
    title: '利用できるクレジットカードは何がありますか？',
    content: `
      VISA、Master Card、JCB、Diners、AmericanExpressが<br />
      ご利用いただけます。なお、海外発行のクレジットカード<br />
      （上記ブランドを含む）や、Discover・銀聯などの上記にないブランド、<br />
      デビットカード・プリペイドカードはご利用いただけません。
    `
  },
  {
    title: 'データ容量を追加で購入できますか？',
    content: '1GBを500円（税込550円）で購入可能です。'
  },
  {
    title: 'データ通信量の確認がしたい',
    content: 'お客様マイページ内の「データご利用状況」よりご確認いただけます。'
  }
]
const DATA2 = [
  {
    title: '5G通信は使えますか？',
    content: `
      ご利用可能です。<br />
      お客様マイページ内の「データご利用状況」から<br />
      5G通信をONにてください。
    `
  },
  {
    title: '初期契約解除制度について',
    content: `
      詳しくは<a href='https://support.xmobile.ne.jp/hc/ja/articles/900003482426'  target='_self'>こちら</a>をご確認ください。
    `
  },
  {
    title: '解約事務手数料はかかりますか？',
    content: '解約事務手数料は発生しません。'
  },
  {
    title: '解約方法について教えてください。',
    content: `ご解約、MNP予約番号発行はマイページよりお手続き可能です。`
  }
]

export default defineComponent({
  setup() {
    const data1 = ref(DATA1)
    const data2 = ref(DATA2)

    return {
      data1,
      data2
    }
  }
})
