
import { mapState, mapMutations } from 'vuex'
import BoxRegisterBtns from '~/components/organisms/home/BoxRegisterBtns.vue'
import { SET_IS_PC } from '~/constants/mutation-types'

export default {
  components: {
    BoxRegisterBtns
  },
  computed: {
    ...mapState({
      isPC: 'isPC'
    }),

    videoHeight() {
      return this.isPC ? 364 : 162
    }
  },
  mounted() {
    this.checkIsPc()
  },
  methods: {
    ...mapMutations({
      setIsPC: SET_IS_PC
    }),

    /**
     * Checking is PC
     */
    checkIsPc() {
      const isPC =
        Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0
        ) >= 992
      this.setIsPC(isPC)
    }
  }
}
