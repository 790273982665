import { render, staticRenderFns } from "./TopBanner.vue?vue&type=template&id=144488d1&scoped=true&"
import script from "./TopBanner.vue?vue&type=script&lang=js&"
export * from "./TopBanner.vue?vue&type=script&lang=js&"
import style0 from "./TopBanner.vue?vue&type=style&index=0&id=144488d1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "144488d1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SsrCarousel: require('/home/gitlab-runner/builds/iVJnzJ8V/0/digidinosvn/horie-mobile/landing-page/node_modules/vue-ssr-carousel/index.js').default})
