
import TopBanner from '~/components/organisms/home/TopBanner.vue'
import Intro from '~/components/organisms/home/Intro.vue'
import RecommendedPoint1 from '~/components/organisms/home/RecommendedPoint1.vue'
import RecommendedPoint2 from '~/components/organisms/home/RecommendedPoint2.vue'
import Future from '~/components/organisms/home/Future.vue'
import PlanReason from '~/components/organisms/home/PlanReason.vue'
import CustomerComment from '~/components/organisms/home/CustomerComment.vue'
import Faq from '~/components/organisms/home/Faq.vue'
import RegisterBtns from '~/components/organisms/home/RegisterBtns.vue'
import Coming from '~/components/organisms/home/Coming.vue'
import Recruiting from '~/components/organisms/home/Recruiting.vue'
import BoxHighlightBtns from '~/components/organisms/home/BoxHighlightBtns.vue'

export default {
  layout: 'user',

  components: {
    TopBanner,
    Intro,
    RecommendedPoint1,
    RecommendedPoint2,
    Future,
    PlanReason,
    CustomerComment,
    Faq,
    RegisterBtns,
    Coming,
    Recruiting,
    BoxHighlightBtns
  },

  data() {
    return {
      highlightBtnsHomeOffset: 500
    }
  },

  beforeMount() {
    window.removeEventListener('scroll', this.onScroll)
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll)

    const btnsHome = document.getElementById('con-box-highlight-btns-home')
    this.highlightBtnsHomeOffset = btnsHome?.getBoundingClientRect().top

    const footer = document.getElementsByClassName('main-user-footer')
    footer[0].style.paddingBottom = '20rem'
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0)
    },

    onScroll(e) {
      const elm = document.getElementById('con-box-highlight-btns-fixed')

      if (window.top.scrollY > this.highlightBtnsHomeOffset) {
        elm?.classList.add('active')
      } else {
        elm?.classList.remove('active')
      }
    }
  }
}
