
import { mapState, mapMutations } from 'vuex'
import { SET_IS_PC } from '~/constants/mutation-types'

export default {
  computed: {
    ...mapState({
      isPC: 'isPC'
    }),

    videoWidth() {
      return this.isPC ? '40%' : '100%'
    },

    videoHeight() {
      return this.isPC ? 420 : 188
    }
  },
  mounted() {
    this.checkIsPc()
  },
  methods: {
    ...mapMutations({
      setIsPC: SET_IS_PC
    }),

    /**
     * Checking is PC
     */
    checkIsPc() {
      const isPC =
        Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0
        ) >= 992
      this.setIsPC(isPC)
    }
  }
}
