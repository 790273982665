
import BoxRegisterBtns from '~/components/organisms/home/BoxRegisterBtns.vue'
import BoxHoriewifiBtns from '~/components/organisms/home/BoxHoriewifiBtns.vue'
import BoxHorimoBtns from '~/components/organisms/home/BoxHorimoBtns.vue'

export default {
  components: {
    BoxRegisterBtns,
    BoxHoriewifiBtns,
    BoxHorimoBtns
  }
}
